import { useEffect, useState } from "react";
import ChannelFinanceCaseDetailsModal from "./ChannelFinanceCaseDetailsModal";
import ChannelFinanceTrackLoanStatus from "./ChannelFinanceTrackLoanStatus";
import { errorToast } from "../../react-toastfiy/toast";
import SearchBoxWithCustomQuery from "../../common/SearchBoxWithCustomQuery";
import channelFinanceService from "../../services/channelFinance/channelFinance.service";
import jwtDecode from "jwt-decode";

function ChannelFinanceSuperAdminDashboard(props) {
  const { state_head } = props;
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);
  const [loadingData, setDataLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loanDetails, setLoanDetails] = useState({});
  const [
    showLoanApplicationTrackingModal,
    setShowLoanApplicationTrackingModal,
  ] = useState(false);

  useEffect(() => {
    try {
      document.title = "Channel Finance Dashboard";

      const form_data = new FormData();
      if (state_head) {
        form_data.append("state_head_phone", user?.phone);
      }

      setDataLoading(true);
      channelFinanceService
        .GetBidderChannelFinanceCaseList(form_data)
        .then((res) => {
          setDataLoading(false);
          if (res.status === 200) {
            setAllData(res.data);
            setFilteredData(res.data);
          } else {
            errorToast(res.msg);
          }
        });
    } catch (error) {
      setDataLoading(false);
      console.log(error);
      errorToast(error);
    }
  }, []);

  return (
    <>
      {showModal && (
        <ChannelFinanceCaseDetailsModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          data={loanDetails}
        />
      )}

      {showLoanApplicationTrackingModal && (
        <ChannelFinanceTrackLoanStatus
          show={showLoanApplicationTrackingModal}
          onHide={() => {
            setShowLoanApplicationTrackingModal(false);
          }}
          asset={loanDetails}
        />
      )}
      <div className=" container">
        <div className=" d-flex justify-content-between align-items-center my-2 gap-2 flex-wrap ">
          <div>
            <span className=" fs-4  border-bottom border-dark pb-1 flex-grow-1">
              Channel Finance Case List
            </span>
          </div>
          <div className=" col-12 col-md-6 ">
            {" "}
            <SearchBoxWithCustomQuery
              allData={allData}
              setFilteredData={setFilteredData}
              placeholder="Search..."
              keysToSearchOn={[
                "make_model",
                "reg_no",
                "state",
                "financier_name",
                "chassis_no",
                "eng_no",
              ]}
            />
          </div>{" "}
        </div>
        <div className=" table-responsive m-0" style={{ maxHeight: "30vh" }}>
          <table className=" table table-bordered table-hover">
            <thead>
              <tr className=" text-center align-middle sticky-top">
                <th>#</th>
                <th>Bidder Name</th>
                <th>Bidder Phone</th>
                <th>State</th>
                <th className=" text-nowrap">Make - Model</th>
                <th>Applied On</th>
                <th>Financier</th>
                <th>Registration No.</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {loadingData ? (
                <>
                  {Array(10)
                    .fill(0)
                    .map((v, i) => {
                      return (
                        <tr key={i}>
                          {Array(10)
                            .fill(0)
                            .map((v, j) => {
                              return (
                                <td key={j}>
                                  <span className="placeholder-glow">
                                    <span className="placeholder col-12 bg-secondary"></span>
                                  </span>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </>
              ) : (
                <>
                  {filteredData &&
                    filteredData?.map((value, i) => {
                      return (
                        <tr key={i} className=" text-center align-middle">
                          <td>{i + 1}</td>
                          <td>{value?.bidder_name}</td>
                          <td>{value?.bidder_phone}</td>
                          <td>{value?.state}</td>
                          <td>{value?.make_model}</td>
                          <td>{value?.datetime?.split(" ")[0]}</td>
                          <td>{value?.financier_name}</td>
                          <td>{value?.reg_no}</td>
                          <td>
                            <button
                              className=" btn btn-primary btn-sm"
                              onClick={() => {
                                setLoanDetails(value);
                                setShowLoanApplicationTrackingModal(true);
                              }}
                            >
                              Status
                            </button>
                          </td>
                          <td>
                            <span
                              className=" text-nowrap text-primary fw-semibold"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowModal(true);
                                setLoanDetails(value);
                              }}
                            >
                              {" "}
                              <i
                                className=" bi bi-eye-fill"
                                onClick={() => {
                                  setShowModal(true);
                                  setLoanDetails(value);
                                }}
                              ></i>{" "}
                              View
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </table>
          {filteredData.length === 0 && !loadingData && (
            <div className="text-danger text-center fw-bold">No Data</div>
          )}
        </div>
      </div>
    </>
  );
}

export default ChannelFinanceSuperAdminDashboard;
