import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import ErrorHandler from "../../common/ErrorHandler";
import { textAlign } from "@mui/system";

const DealerShipCountModel = (props) => {
  const { data, ageing, loan_amount, handleClickWishList, id } = props;

  try {
    return (
      <>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Dealership List
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Dealer Code</th>
                  <th>Dealer Name</th>
                  <th style={{ textAlign: "center" }}>WishList</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((ele, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{ele.dealership_code}</td>
                        <td>{ele.dealership_name}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="outline-primary"
                            onClick={(e) =>
                              handleClickWishList(
                                e,
                                ele.dealership_code,
                                ele.dealership_name
                              )
                            }
                            style={{ cursor: "pointer", textAlign: "center" }}
                          >
                            Manage
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {data.length === 0 && (
              <p className="text-center text-danger">No data</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default DealerShipCountModel;
