import React from "react";
import { Route, Redirect } from "react-router-dom";
import ErrorHandler from "./ErrorHandler";
import jwt from "jwt-decode";

const ChangeRoute = ({ component: RouteComponent, ...rest }) => {
  const currentUser = localStorage.getItem("refreshToken");
  let user;
  let user_role;
  if (currentUser) {
    user = jwt(currentUser);
    user_role = localStorage.getItem("user_role");
  }

  try {
    return (
      <Route
        {...rest}
        render={(routeProps) => {
          if (currentUser) {
            if (user_role === "Developer" || user_role === "Super Admin") {
              return (
                <Redirect
                  to={"/superadminhome:" + user.phone + "&state=" + user.state}
                />
              );
            } else if (user_role === "Territory Manager")
              return (
                <Redirect
                  to={"/tmDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Business Analyst")
              return (
                <Redirect
                  to={
                    "/business_analyst:" + user.phone + "&state=" + user.state
                  }
                />
              );
            else if (user_role === "Auction Representative")
              return (
                <Redirect
                  to={
                    "/auction_representative:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Auction Super Admin")
              return (
                <Redirect
                  to={"/asaDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Auction Demo User")
              return (
                <Redirect
                  to={"/aduDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === `State Head`)
              return (
                <Redirect
                  to={"/shDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "RTO Personnel")
              return (
                <Redirect
                  to={"/rtoDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Account Personnel")
              return (
                <Redirect
                  to={"/apDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Area Manager")
              return (
                <Redirect
                  to={"/amDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Dealership Business Head")
              return (
                <Redirect
                  to={"/bdhDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Bharat Loan Business Development Manager")
              return (
                <Redirect
                  to={
                    "/bharat-loan-business-development-manager-Dashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Bharat Loan State Head")
              return (
                <Redirect
                  to={
                    "/bharat-loan-state-head-Dashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (
              user_role === "Bharat Loan Senior Business Development Manager"
            )
              return (
                <Redirect
                  to={
                    "/bharat-loan-senior-business-development-manager-Dashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (
              user_role === "RTO Personnel" &&
              user.state === "Karnataka"
            )
              return (
                <Redirect
                  to={
                    "/bharat-loan-rto-Dashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Bharat Loan Business Head")
              return (
                <Redirect
                  to={
                    "/bharat-loan-business-head-Dashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Head - Risk And Process")
              return (
                <Redirect
                  to={
                    "/risk-management-dashbaord:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Back Office")
              return (
                <Redirect
                  to={
                    "/backOfficeDashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Manufacturer")
              return (
                <Redirect
                  to={
                    "/manufacturerDashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Head Office")
              return (
                <Redirect
                  to={
                    "/headOfficeDashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Strategy Head")
              return (
                <Redirect
                  to={
                    "/strategyHeadDashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Human Resources")
              return (
                <Redirect
                  to={"/hrDashboard:" + user.phone + "&state=" + user.state}
                />
              );
            else if (user_role === "Franchise Owner(implement-dealer)")
              return (
                <Redirect
                  to={
                    "/superDealerDashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "State Office")
              return (
                <Redirect
                  to={
                    "/stateOfficeDashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Auction Back Office")
              return (
                <Redirect
                  to={
                    "/auction-back-office-dashboard:" +
                    user.phone +
                    "&state=" +
                    user.state
                  }
                />
              );
            else if (user_role === "Auction Buyer")
              return (
                <Redirect
                  to={`/auction/auction-buyer-dashboard:${user?.phone}`}
                />
              );
            else if (user_role === "Yard Owner")
              return <Redirect to={`/yard-owner/yard-owner-home`} />;
            else {
              return <Redirect to="/coming-soon" />;
            }
          } else {
            return <Redirect to="/sign-in" />;
          }
        }}
      />
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default ChangeRoute;
