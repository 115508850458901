import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import SearchBox from "../../common/SearchBox";
import TableLoading from "../../common/TableLoading";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import channelFinanceService from "../../services/channelFinance/channelFinance.service";
import stateService from "../../services/state.service";
import ChannelFinanceViewDocumentsModal from "../channelFinance/ChannelFinanceViewDocumentsModal";

const ChannelFinanceDocumentsVerificationForBusinessAnalyst = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);

     useEffect(() => {
       try {
         document.title = "Channel Finance";
       } catch (error) {
         console.error(error);
         errorToast(error?.message);
       }
     }, []);

  const OptionSelect = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "approved",
      label: "Approved",
    },
    {
      value: "rejected",
      label: "Rejected",
    },
  ];

  const [viewDocModal, setViewDocModal] = useState(false);
  const [viewDocModalData, setViewDocModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [approvalDataList, setApprovalDataList] = useState([]);
  const [filterApprovalDataList, setFilterApprovalDataList] = useState([]);
  const [selectStatus, setSelectStatus] = useState({
    value: "pending",
    label: "Pending",
  });
  const [selectedValue, setSelectedValue] = useState("all");
  const [stateList, setStateList] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      const form_data = new FormData();
      form_data.append("status", "pending");
      channelFinanceService
        .GetAllDocsVerificationPendingCompletedByBusinessAnalyst(form_data)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setApprovalDataList(res?.data);
            setFilterApprovalDataList(res?.data);
          } else {
            errorToast(res?.msg);
          }
        });
    } catch (error) {}
  }, []);

  const handleApprovalOrReject = (e, case_id, type, name, phone) => {
    try {
      e.preventDefault();

      if (type === "approved") {
        Swal.fire({
          title: "Are You Sure?",
          text: `You want to approve this request! (${name} - ${phone})`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes,  Approve !`,
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            try {
              let close_request_data = new FormData();
              close_request_data.append("status", type);
              close_request_data.append("request_type", "BA");
              close_request_data.append("case_id", case_id);
              const response =
                await channelFinanceService.DocsApprovalAndReject(
                  close_request_data
                );
              if (response.status === 200) {
                successToast(response.msg);
                const form_data = new FormData();
                form_data.append("status", "pending");
                channelFinanceService
                  .GetAllDocsVerificationPendingCompletedByBusinessAnalyst(
                    form_data
                  )
                  .then((res) => {
                    setLoading(false);
                    if (res?.status === 200) {
                      setApprovalDataList(res?.data);
                      setFilterApprovalDataList(res?.data);
                    } else {
                      errorToast(res?.msg);
                    }
                  });
              }
              response.status !== 200 && errorToast(response.msg);
            } catch (error) {
              console.error(error);
              errorToast(error?.message);
            }
          },
        });
      } else {
        Swal.fire({
          title: "Are You Sure?",
          text: `You want to reject this request!`,
          input: "text",
          inputLabel: "Rejection Reason",
          inputAttributes: {
            autocapitalize: "off",
            placeholder: "Enter Case Rejection Reason",
          },
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: `Yes,  Reject !`,
          showLoaderOnConfirm: true,
          preConfirm: async (reason) => {
            if (!reason || reason.trim() === "") {
              Swal.showValidationMessage("Rejection reason cannot be blank");
              return false;
            }
            try {
              const case_reason = reason;
              let close_request_data = new FormData();
              close_request_data.append("status", type);
              close_request_data.append("request_type", "BA");
              close_request_data.append("case_id", case_id);
              close_request_data.append("rejection_reason", case_reason);
              const response =
                await channelFinanceService.DocsApprovalAndReject(
                  close_request_data
                );
              if (response.status === 200) {
                successToast(response.msg);
                const form_data = new FormData();
                form_data.append("status", "pending");
                channelFinanceService
                  .GetAllDocsVerificationPendingCompletedByBusinessAnalyst(
                    form_data
                  )
                  .then((res) => {
                    setLoading(false);
                    if (res?.status === 200) {
                      setApprovalDataList(res?.data);
                      setFilterApprovalDataList(res?.data);
                    } else {
                      errorToast(res?.msg);
                    }
                  });
              }

              response.status !== 200 && errorToast(response.msg);
            } catch (error) {
              console.error(error);
              errorToast(error?.message);
            }
          },
        });
      }
    } catch (error) {}
  };


  const handleSelect = (e) => {
    try {
      setLoading(true);
      setSelectStatus(e);
      setSelectedValue("");
      const form_data = new FormData();
      form_data.append("status", e?.value);
      channelFinanceService
        .GetAllDocsVerificationPendingCompletedByBusinessAnalyst(form_data)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            setApprovalDataList(res?.data);
            setFilterApprovalDataList(res?.data);
          } else {
            errorToast(res?.msg);
          }
        });
    } catch (error) {
      errorToast(error?.msg);
    }
  };

  useEffect(() => {
    try {
      setStateLoading(true);
      stateService.getAllState().then((res) => {
        setStateLoading(false);
        if (res?.length > 0) {
          const state_list = res.map((val) => ({
            label: val?.state_name,
            value: val,
          }));
          const new_state_list = [
            { label: "All", value: "all" },
            ...state_list,
          ];
          setStateList(new_state_list);
        }
      });
    } catch (error) {}
  }, []);

  const handleSelectState = (e) => {
    if (e?.label !== "All") {
      const new_Data = approvalDataList.filter(
        (val, key) => val?.state === e?.label
      );
      setFilterApprovalDataList(new_Data);
      setSelectedValue(e?.label);
    } else {
      setFilterApprovalDataList(approvalDataList);
      setSelectedValue(e?.label);
    }
  };

  return (
    <>
      {viewDocModal && (
        <ChannelFinanceViewDocumentsModal
          show={viewDocModal}
          data={viewDocModalData}
          onHide={() => {
            setViewDocModal(false);
          }}
        />
      )}
      <div className="container responsive mt-2">
        <div className="row mt-2 ms-auto">
          <div className="col-10 col-sm-12 col-md-6 col-lg-6 d-flex d-flex-wrap justify-content-between mt-2">
            <h4 className="">Channel Finance Documents Approval Pending</h4>
          </div>
          <div className="d-flex flex-wrap justify-content-between ">
            <div
              className="col-12 col-sm-12 col-md-4 col-lg-3 mt-2"
              style={{
                marginBottom: "4px",
                fontSize: "small",
                position: "sticky",
                top: 0,
                zIndex: 5,
              }}
            >
              <Select
                options={stateList}
                onChange={handleSelectState}
                value={stateList.find(
                  (option) => option.value === selectedValue
                )}
                placeholder="Select State"
              />
            </div>
            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3 mt-2"
              style={{
                marginBottom: "4px",
                fontSize: "small",
                position: "sticky",
                top: 0,
                zIndex: 4,
              }}
            >
              <Select
                options={OptionSelect}
                onChange={handleSelect}
                value={selectStatus}
                placeholder="Select Documents Status"
              />
            </div>
            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3  mt-2 me-2"
              style={{ marginBottom: "4px" }}
            >
              <SearchBox
                placeholder="Search by First Name, Phone,State"
                allData={approvalDataList}
                setFilteredData={setFilterApprovalDataList}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-2">
        <div className="d-flex d-flex-wrap justify-content-between">
          <div
            className="table-responsive mt-3 container"
            style={{ minHeight: "5rem", maxHeight: "60vh" }}
          >
            <table className="table table-bordered table-hover">
              <thead className="thead-dark">
                <tr
                  className="text-center"
                  style={{
                    fontSize: "small",
                    position: "sticky",
                    top: -9,
                    zIndex: 2,
                  }}
                >
                  <th>No</th>
                  <th>Phone</th>
                  <th>Name</th>
                  <th>Created By</th>
                  <th>State</th>
                  <th>View Details</th>
                  <th>Current Status</th>
                  {selectStatus?.value === "rejected" && (
                    <th>Rejection Reason</th>
                  )}

                  {selectStatus?.value === "pending" && (
                    <th
                      style={{
                        minWidth: "6rem",
                        position: "sticky",
                        top: 0,
                        right: "-15px",
                        zIndex: 3,
                      }}
                    >
                      Action
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    {selectStatus?.value === "pending" ? (
                      <TableLoading rows={5} cols={9} />
                    ) : (
                      <TableLoading rows={5} cols={7} />
                    )}
                  </>
                ) : (
                  Object.entries(filterApprovalDataList).map(
                    ([key, value], index) => (
                      <tr className="text-center" key={index}>
                        <td>{index + 1}</td>
                        <td>{value?.phone}</td>
                        <td>
                          {`${value?.first_name} ${value?.middle_name || ""} ${
                            value?.last_name
                          }`.trim()}
                        </td>
                        <td>{value?.created_by_name}</td>
                        <td>{value?.state}</td>
                        <td className="text-nowrap">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setViewDocModal(true);
                              setViewDocModalData(value);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="bi bi-eye-fill text-white"></i> View
                          </button>
                        </td>
                        <td>
                          <b>{value?.current_status}</b>
                        </td>
                        {selectStatus?.value === "rejected" && (
                          <>
                            {value?.rejection_reason === "" ? (
                              <td>-</td>
                            ) : (
                              <td className="text-danger">
                                <b>{value?.rejection_reason}</b>
                              </td>
                            )}
                          </>
                        )}
                        {selectStatus?.value === "pending" && (
                          <td
                            style={{
                              minWidth: "6rem",
                              position: "sticky",
                              top: 0,
                              right: "-15px",
                              zIndex: 3,
                            }}
                          >
                            <div className="d-flex d-flex-wrap justify-content-center gap-3">
                              <button
                                className="btn btn-success btn-sm"
                                onClick={(e) => {
                                  handleApprovalOrReject(
                                    e,
                                    value?.case_id,
                                    "approved",
                                    `${value?.first_name} ${
                                      value?.middle_name || ""
                                    } ${value?.last_name}`.trim(),
                                    value?.phone
                                  );
                                }}
                                disabled={
                                  selectStatus?.value === "approved" ||
                                  selectStatus?.value === "rejected"
                                }
                              >
                                <i class="bi bi-check"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={(e) => {
                                  handleApprovalOrReject(
                                    e,
                                    value?.case_id,
                                    "rejected",
                                    `${value?.first_name} ${
                                      value?.middle_name || ""
                                    } ${value?.last_name}`.trim(),
                                    value?.phone
                                  );
                                }}
                                disabled={
                                  selectStatus?.value === "approved" ||
                                  selectStatus?.value === "rejected"
                                }
                              >
                                <i class="bi bi-x-circle"></i>
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Object.entries(filterApprovalDataList)?.length === 0 && !loading && (
        <p className=" fw-semibold text-danger text-center">No Data</p>
      )}
    </>
  );
};

export default ChannelFinanceDocumentsVerificationForBusinessAnalyst;
