import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DealerShipCountModel from "../components/dashboard/DealershipCountModel";
import { errorToast } from "../react-toastfiy/toast";
import ErrorHandler from "./ErrorHandler";
import dashboardService from "../services/dashboard.service";

function UserNameAndDealershipDetails() {
  const location = useLocation();
  let { id } = useParams();
  const [name, setName] = useState("");
  let history = useHistory();
  // let location = useLocation();
  const [modelDealerCount, setModelDealerCount] = useState(false);
  const [dealerShipCountList, setDealerShipCountList] = useState({});
  const [dealershipCount, setDealershipCount] = useState(0);

  const [phone, setPhone] = useState(id.split(":")[1].split("&")[0]);
  const [stateName, setStateName] = useState(
    id.split(":")[1].split("&")[1].split("=")[1]
  );

  useEffect(() => {
    try {
      let userDetails = new FormData();
      userDetails.append("phone", phone);
      dashboardService.getUserDetailsByPhone(userDetails).then((res) => {
        setName(res.data.name + "(" + res.data.designation + ")");
      });
      dashboardService.GetAssignedDealershipCount(userDetails).then((res) => {
        {
          res.status === 200
            ? setDealershipCount(res.data)
            : errorToast(res.msg);
        }
      });
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  }, []);
  const handleClickWishList = (e, dealership_code, dealership_name) => {
    let path = `/stockWishList/${dealership_code}`;
    history.push({
      pathname: path,
      state: { dealership_code, dealership_name },
    });
  };

  try {
    return (
      <>
        {modelDealerCount && (
          <DealerShipCountModel
            show={modelDealerCount}
            data={dealerShipCountList}
            handleClickWishList={handleClickWishList}
            id={id}
            // ageing={false}
            // loan_amount={true}
            // dialogClassName="modal-90w"
            // aria-labelledby="example-custom-modal-styling-title"
            onHide={() => {
              setModelDealerCount(false);
            }}
          />
        )}
        <div className="container-fluid shadow-sm">
          <div className="container">
            <div className="row">
              <div className="d-sm-block d-md-none d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12">
                    <h1 style={{ fontSize: "40px" }}>{name}</h1>
                  </div>
                  <div className="col-12">
                    <h3
                      className="tmP"
                      style={{
                        float: "right !important",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setModelDealerCount(true);
                        setDealerShipCountList(dealershipCount.data);
                      }}
                    >
                      Dealerships Assigned -
                      {dealershipCount.count ? dealershipCount.count : 0}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-block d-lg-none d-xl-none">
                <div className="row">
                  <div className="col-12 col-md-6 mt-3">
                    <h2>{name}</h2>
                  </div>
                  <div className="col-12 col-md-6 mt-3">
                    <h3
                      className="tmP"
                      style={{
                        float: "right !important",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setModelDealerCount(true);
                        setDealerShipCountList(dealershipCount.data);
                      }}
                    >
                      Dealerships Assigned -{" "}
                      {dealershipCount.count ? dealershipCount.count : 0}
                    </h3>
                  </div>
                </div>
              </div>

              <div className="d-none d-md-none d-lg-block d-xl-block">
                <div className="row">
                  <div className="col-12 col-md-8 mt-3">
                    <h2>{name}</h2>
                  </div>
                  <div className="col-12 col-md-4 mt-3 float-right">
                    <h3
                      className="tmP"
                      style={{
                        float: "right !important",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setModelDealerCount(true);
                        setDealerShipCountList(dealershipCount.data);
                      }}
                    >
                      Dealerships Assigned -{" "}
                      {dealershipCount.count ? dealershipCount.count : 0}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default UserNameAndDealershipDetails;
