import React, { useEffect, useState } from "react";
import "../../css/dashboard.css";
import ErrorHandler from "../../common/ErrorHandler";
import ChannelFinanceSuperAdminDashboard from "../ChannelFinanace/ChannelFinanceSuperAdminDashboard";
import AuctionSummaryDashboardData from "../auction/AuctionSummaryDashboardData";
import FinancierWiseAuctionList from "../auction/FinancierWiseAuctionList";

function ARDashboardTabbar() {
  const [active, setactive] = useState("auction_dashboard")
  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");

    setactive(id);
    localStorage.setItem("active_sub_tab", id);
  };

  useEffect(() => {
    const lastActiveTab = localStorage.getItem("active_sub_tab");
    if (lastActiveTab) {
      setactive(lastActiveTab);
    }
  }, []);

  try {
    return (
      <>
        <>
          <div className="container mt-3">
            <ul className="nav nav-tabs">
         
              <li className="nav-item">
                <button
                  className={
                    active === "auction_dashboard"
                      ? `nav-link active btn btn-link`
                      : `nav-link btn btn-link`
                  }
                  id="auction_dashboard"
                  onClick={handlechangetab}
                >
                  Auction
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    active === "auction_summary_data"
                      ? `nav-link active btn btn-link`
                      : `nav-link btn btn-link`
                  }
                  id="auction_summary_data"
                  onClick={handlechangetab}
                >
                  Auction Summary Data
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    active === "channel-finance-case-list"
                      ? `nav-link active btn btn-link`
                      : `nav-link btn btn-link`
                  }
                  id="channel-finance-case-list"
                  onClick={handlechangetab}
                >
                  Channel Finance Dashboard
                </button>
              </li>
            </ul>
          </div>

          {active === "auction_dashboard" && (
            <>
              <FinancierWiseAuctionList />
            </>
          )}
          {active === "auction_summary_data" && (
            <>
              <AuctionSummaryDashboardData />
            </>
          )}
          {active === "channel-finance-case-list" && (
            <>
              <ChannelFinanceSuperAdminDashboard state_head={true} />
            </>
          )}
        </>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default ARDashboardTabbar;
