import jwtDecode from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactLoading from "react-loading";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import channelFinanceService from "../../services/channelFinance/channelFinance.service";

function ChannelFinanceForm(props) {
  const {
    data,
    edit_form,
    setChannelFinance,
    setFilterApprovalDataList,
    setApprovalDataList,
  } = props;
  const refreshToken = localStorage.getItem("refreshToken");
  const user = jwtDecode(refreshToken);
  const phone = user?.phone;
  const initialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    state: "",
    phone: "",
    email: "",
    aadhar_no: "",
    pan_no: "",
    aadhar_front_url: "",
    aadhar_back_url: "",
    pan_url: "",
    address: "",
    reporting_personal: user?.user_id,
    assign_cluster: [],
    is_blocked: false,
    assigned_states_designations: [],
    user_designation: 49,
    pincode: "",
    is_active: false,
  };
  const [loadingSubmitBtn, setLoadingSubmitBtn] = useState(false);
  const [userFormData, setUserFormData] = useState(
    edit_form ? data : initialValues
  );
  const [debounceVal, setDebounceVal] = useState("");
  const [userDataKey, setUserDataKey] = useState({});
  const [loading, setLoading] = useState(false);
  const [userData_is_available, setUserData_is_available] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function debounce(cb, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const debouncedChange = useCallback(
    debounce((inputValue) => {
      setDebounceVal(inputValue);
    }, 500),
    []
  );

  const handleChangeDebounce = (e) => {
    const inputValue = e.target.value;
    debouncedChange(inputValue);
    setUserFormData((prev) => ({
      ...prev,
      ["phone"]: inputValue,
    }));
  };

  const handleChange = (e) => {
    let { name, value, files } = e.target;
    if (
      [
        "aadhar_front_url",
        "aadhar_back_url",
        "pan_url",
        "constitutional_documents_url",
        "gst_reg_certificate_url",
        "bank_statement_url",
      ].includes(name)
    ) {
      setUserFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else if (name === "is_subscribed" && value === false) {
      setUserFormData((prev) => ({
        ...prev,
        [name]: value,
        subscription_date: "",
        subscription_tenure: "",
      }));
    } else {
      setUserFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setUserFormData((prev) => {
      return {
        ...prev,
        [name]: value.toString().trim(),
      };
    });
  };

  useEffect(() => {
    if (debounceVal.length === 10) {
      const fetchData = async () => {
        setLoading(true);

        let formData = new FormData();
        formData.append("phone", debounceVal);
        const response = await channelFinanceService.GetUserExists(formData);

        setLoading(false);
        if (response?.status === 200) {
          setUserData_is_available(true);
          const matchingState = allStates.find(
            (state) => state.label === response.data.state_name
          );

          const defaultData = {
            pan_url: "",
            is_blocked: false,
            assigned_states_designations: [
              {
                state: matchingState?.label,
                designations: ["Auction Buyer"],
              },
            ],
            user_designation: 49,
            reporting_personal_id: 521,
          };
          const user_Form_Data = {
            ...defaultData,
            ...response?.data,
          };
          const emptyKeys = Object.entries(user_Form_Data)
            .filter(
              ([key, value]) =>
                value === "" ||
                value === null ||
                value === "-" ||
                (Array.isArray(value) && value.length === 0)
            )
            .reduce((acc, [key, value]) => {
              acc[key] = value;
              return acc;
            }, {});

          setUserDataKey(emptyKeys);
          if (response?.user_exists === true) {
            setUserFormData(user_Form_Data);
          } else {
            setUserFormData({
              ...initialValues,
              ["phone"]: debounceVal,
            });
            errorToast(response?.msg);
          }
        } else {
          errorToast(response?.msg);
          setUserData_is_available(false);
          setUserDataKey(initialValues);
          setUserFormData({
            ...initialValues,
            ["phone"]: debounceVal,
          });
        }
      };
      fetchData();
    }
  }, [debounceVal, allStates]);



  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoadingSubmitBtn(true);
      const userData = structuredClone(userFormData);
      const { aadhar_no, phone, pan_no } = userData;
      const errors = {};
      if (!phone) errors.phone = "Phone is required";
      if (!aadhar_no) errors.aadhar_no = "Aadhar is required";
      if (!pan_no) errors.pan_no = "Pan No is required";

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }

      let form_data = new FormData();
      if (edit_form) {
        form_data.append("case_id", userData?.case_id);
      }
     
      form_data.append("phone", userData.phone);
      form_data.append("aadhar_no", userData.aadhar_no);
      form_data.append("pan_no", userData.pan_no);
      form_data.append("aadhar_front", userData.aadhar_front_url);
      form_data.append("aadhar_back", userData.aadhar_back_url);
      form_data.append("pan_file", userData.pan_url);
      form_data.append("gst_reg_certificate", userData.gst_reg_certificate_url);
      form_data.append("bank_statement", userData.bank_statement_url);
      form_data.append(
        "constitutional_documents",
        userData.constitutional_documents_url
      );

      let response = "";
      if (edit_form) {
        response = await channelFinanceService.UpdateUser(form_data);
      } else {
        response = await channelFinanceService.CreateUser(form_data);
      }
      setLoadingSubmitBtn(false);
      if (response.status === 200) {
        setChannelFinance(false);
        successToast(response.msg);
        const form_data = new FormData();
        form_data.append("status", user?.phone);
        channelFinanceService
          .GetListAppliedForChannelFinanceCase()
          .then((res) => {
            setLoading(false);
            if (res?.status === 200) {
              setApprovalDataList(res?.data);
              setFilterApprovalDataList(res?.data);
            } else {
              errorToast(res?.msg);
            }
          });
       window.location.reload();
      } else {
        errorToast(response.msg);
      }
    } catch (error) {
      console.log(error?.msg);
    }
  };

  try {
    return (
      <>
        <Modal
          size="lg"
          backdrop="static"
          aaria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.show}
          onHide={props.onHide}
        >
          <Modal.Header closeButton>
            {edit_form ? (
              <h2 className=" text-start"> Update User For Channel Finance</h2>
            ) : (
              <h2 className=" text-start"> Add User For Channel Finance</h2>
            )}
          </Modal.Header>
          <form className="ms-1" onSubmit={handleSubmit}>
            <div class="modal-body">
              <div
                className="container"
                style={{ overflowY: "scroll", maxHeight: "70vh" }}
              >
                <div className="col-12">
                  <label htmlFor="phone" className=" form-label mb-0">
                    Phone (WhatsApp number)
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={userFormData?.phone}
                    onChange={(e) => {
                      const cleanedValue = e.target.value
                        .slice(0, 10)
                        .replaceAll(/\D+/g, "");
                      e.target.value = cleanedValue;
                      handleChangeDebounce(e);
                    }}
                    onBlur={handleBlur}
                    className=" form-control"
                    placeholder="Phone number"
                    pattern="[0-9]{10}"
                    required
                    style={{
                      paddingRight: loading ? "30px" : "10px",
                    }}
                  />

                  {userFormData?.phone?.length > 0 &&
                    !/^[0-9]{10}$/.test(userFormData?.phone) && (
                      <span className="text-danger">
                        Phone number must have 10 digits
                      </span>
                    )}

                  {loading && (
                    <div className=" d-flex justify-content-start ">
                      <ReactLoading
                        type={"bubbles"}
                        color={"gray"}
                        height={50}
                        width={55}
                      />
                    </div>
                  )}
                </div>
                <div className="row mt-1">
                  <div className="col-12 col-md-6 col-lg-6">
                    <label htmlFor="first_name" className=" form-label mb-0">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={userFormData?.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="First name"
                      required
                      autoCapitalize="characters"
                      disabled={
                        loading || userDataKey?.first_name === undefined
                      }
                      isLoading={loading}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <label htmlFor="middle_name" className=" form-label mb-0">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      name="middle_name"
                      id="middle_name"
                      value={userFormData?.middle_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                      placeholder="Middle name"
                      // required
                      disabled
                    />
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 col-md-6">
                    <label htmlFor="last_name" className=" form-label mb-0">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={userFormData?.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Last name"
                      required
                      disabled={loading || userDataKey?.last_name === undefined}
                      loading={loading}
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <label htmlFor="email" className=" form-label mb-0">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={userFormData?.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Email address"
                      disabled={loading || userDataKey?.email === undefined}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="address" className=" form-label mb-0">
                    Address
                  </label>
                  <textarea
                    type="text-area"
                    name="address"
                    id="address"
                    value={userFormData?.address}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    className=" form-control"
                    placeholder="Address"
                    required
                    disabled
                  />
                </div>
                <div className="row mt-1">
                  <div className="col-12 col-md-6">
                    <label htmlFor="state" className=" form-label mb-0">
                      Select State
                    </label>
                    <input
                      type="text"
                      name="state"
                      id="state"
                      value={userFormData?.state}
                      className=" form-control"
                      placeholder="State"
                      disabled
                    />

                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="pincode" className=" form-label mb-0">
                      Pincode
                    </label>
                    <input
                      type="text"
                      name="pincode"
                      id="pincode"
                      value={userFormData?.pincode}
                      onChange={(e) => {
                        const cleanedValue = e.target.value
                          .slice(0, 6)
                          .replaceAll(/\D+/g, "");
                        e.target.value = cleanedValue;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="Pin code"
                      pattern="[0-9]{6}"
                      required
                      disabled={loading || userDataKey?.pincode === undefined}
                    />
                    {userFormData?.pincode?.length > 0 && (
                      <span className=" invalid-feedback">
                        Pincode must have 6 digits
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-1">
                  <label htmlFor="aadhar_no" className=" form-label mb-0">
                    Aadhar
                  </label>
                  <input
                    type="text"
                    name="aadhar_no"
                    id="aadhar_no"
                    value={userFormData?.aadhar_no}
                    onChange={(e) => {
                      const cleanedValue = e.target.value
                        .slice(0, 12)
                        .replaceAll(/\D+/g, "");
                      e.target.value = cleanedValue;
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    className=" form-control"
                    placeholder="Aadhar"
                    pattern="[0-9]{12}"
                    required
                    // disabled={loading || userDataKey?.aadhar_no === undefined}
                  />
                  {userFormData?.aadhar_no?.length > 0 && (
                    <span className=" invalid-feedback">
                      Aadhar must have 12 digits
                    </span>
                  )}
                </div>
                <div className="row mt-1">
                  <div className="col-12 col-md-6">
                    <label
                      htmlFor="aadhar_front_url"
                      className=" form-label mb-0"
                    >
                      Upload Front Side Of Aadhar
                    </label>
                    <input
                      type="file"
                      name="aadhar_front_url"
                      id="aadhar_front_url"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.aadhar_front_url}
                    
                    />
                    {!!userFormData?.aadhar_front_url && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.aadhar_front_url === "object"
                              ? URL.createObjectURL(
                                  userFormData?.aadhar_front_url
                                )
                              : userFormData?.aadhar_front_url
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6">
                    <label
                      htmlFor="aadhar_back_url"
                      className=" form-label mb-0"
                    >
                      Upload Back Side Of Aadhar
                    </label>
                    <input
                      type="file"
                      name="aadhar_back_url"
                      id="aadhar_back_url"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.aadhar_back_url}
                      // disabled={
                      //   loading || userDataKey?.aadhar_back_url === undefined
                      // }
                    />
                    {!!userFormData?.aadhar_back_url && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.aadhar_back_url === "object"
                              ? URL.createObjectURL(
                                  userFormData?.aadhar_back_url
                                )
                              : userFormData?.aadhar_back_url
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 col-md-6">
                    <label htmlFor="pan_no" className=" form-label mb-0">
                      PAN
                    </label>
                    <input
                      type="text"
                      name="pan_no"
                      id="pan_no"
                      value={userFormData?.pan_no}
                      onChange={(e) => {
                        const cleanedValue = e.target.value
                          .slice(0, 10)
                          .replaceAll(/[^a-zA-Z0-9]/g, "")
                          .toUpperCase();
                        e.target.value = cleanedValue;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      className=" form-control"
                      placeholder="PAN"
                      pattern="[a-zA-Z0-9]{10}"
                      required
                      // disabled={loading || userDataKey?.pan_no === undefined}
                    />
                    {userFormData?.pan_no?.length > 0 && (
                      <span className=" invalid-feedback">
                        PAN must be length of 10
                      </span>
                    )}
                  </div>
                  <div className="col-12 col-md-6">
                    <label htmlFor="pan_url" className=" form-label mb-0">
                      Upload PAN
                    </label>
                    <input
                      type="file"
                      name="pan_url"
                      id="pan_url"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.pan_url}
                      // disabled={loading || userDataKey?.pan_url === undefined}
                    />
                    {!!userFormData?.pan_url && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.pan_url === "object"
                              ? URL.createObjectURL(userFormData?.pan_url)
                              : userFormData?.pan_url
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          isDisabled={loading}
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-12 col-md-6">
                    <label
                      htmlFor="bank_statement_url"
                      className=" form-label mb-0"
                    >
                      Upload 6 Month Bank Statement
                    </label>
                    <input
                      type="file"
                      name="bank_statement_url"
                      id="bank_statement_url"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.bank_statement_url}
                    />
                    {!!userFormData?.bank_statement_url && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.bank_statement_url === "object"
                              ? URL.createObjectURL(
                                  userFormData?.bank_statement_url
                                )
                              : userFormData?.bank_statement_url
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          isDisabled={loading}
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6">
                    <label
                      htmlFor="gst_reg_certificate_url"
                      className=" form-label mb-0"
                    >
                      GST Registration Certificate
                    </label>
                    <input
                      type="file"
                      name="gst_reg_certificate_url"
                      id="gst_reg_certificate_url"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      className=" form-control"
                      required={!userFormData?.gst_reg_certificate_url}
                    />
                    {!!userFormData?.gst_reg_certificate_url && (
                      <div className="d-flex">
                        <p className="text-danger me-1 mb-0">
                          Uploaded file :{" "}
                        </p>
                        <a
                          href={
                            typeof userFormData?.gst_reg_certificate_url ===
                            "object"
                              ? URL.createObjectURL(
                                  userFormData?.gst_reg_certificate_url
                                )
                              : userFormData?.gst_reg_certificate_url
                          }
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          rel="noreferrer"
                          isDisabled={loading}
                        >
                          <i class="bi bi-cloud-arrow-down-fill"></i> Download
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mt-1">
                  <label
                    htmlFor="constitutional_documents_url"
                    className=" form-label mb-0"
                  >
                    Constitutional Document
                  </label>
                  <input
                    type="file"
                    name="constitutional_documents_url"
                    id="constitutional_documents_url"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className=" form-control"
                    placeholder="no file"
                    required={!userFormData?.constitutional_documents_url}
                  />

                  {!!userFormData?.constitutional_documents_url && (
                    <div className="d-flex">
                      <p className="text-danger me-1 mb-0">Uploaded file : </p>
                      <a
                        href={
                          typeof userFormData?.constitutional_documents_url ===
                          "object"
                            ? URL.createObjectURL(
                                userFormData?.constitutional_documents_url
                              )
                            : userFormData?.constitutional_documents_url
                        }
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        rel="noreferrer"
                        isDisabled={loading}
                      >
                        <i class="bi bi-cloud-arrow-down-fill"></i> Download
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal.Footer>
              <button
                className=" btn btn-secondary"
                onClick={props.onHide}
                disabled={loadingSubmitBtn}
              >
                Close
              </button>
              {loadingSubmitBtn ? (
                <button className=" btn btn-success" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              ) : (
                <button className=" btn btn-success">
                  {edit_form ? "Re-apply" : "Add User"}
                </button>
              )}
            </Modal.Footer>
          </form>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </>
    );
  } catch (error) {
    console.log(error);
  }
}
export default ChannelFinanceForm;
