import axios from "axios";
import { errorToast } from "../../react-toastfiy/toast";

class ChannelFinanceService {
  GetChannelFinanceDataById = async (formdata, source = {}) => {
    return axios({
      method: "post",
      url: `/users/get-channel-finance-data-by-id`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetListOfChannelFinanceForRisk = async (formdata, source = {}) => {
    return axios({
      method: "post",
      url: `/users/get-list-of-channel-finance-for-risk`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  RiskTempChannelFinanceFileUpload = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/risk-temp-channel-finance-file-upload`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetListOfChannelFinanceForAuctionAdmin = async (formdata, source = {}) => {
    return axios({
      method: "post",
      url: `/users/get-list-of-channel-finance-for-auction-admin`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
      cancelToken: source?.token,
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  AuctionAdminTempChannelFinanceFileUpload = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/auction-admin-temp-channel-finance-file-upload`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  RiskChannelFinanceSellerUTRUpdate = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/risk-channel-finance-seller-utr-update`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  RiskChannelFinanceReleaseOrderPendingList = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-list-of-channel-finance-release-order`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  RiskChannelFinanceReleaseOrderUpload = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/channel-finance-release-order-upload`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  ChannelFinanceRejection = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/temp-channel-finance-rejection`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetListOfChannelFinanceStockInward = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-list-of-channel-finance-stock-inward`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetListOfChannelFinanceYardOwnerUploadedDataVerification = async (
    formdata
  ) => {
    return axios({
      method: "post",
      url: `/users/get-list-of-channel-finance-yard-owner-uploaded-data-verification`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  ChannelFinanceYardOwnerUploadedDataVerification = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/channel-finance-yard-owner-uploaded-data-verification`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  StockInwardReceiptUpload = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/stock-inward-receipt-upload`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  ChannelFinanceFileSave = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/channel-finance-file-save`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  ChannelFinanceStockInwardReceiptUpload = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/stock-inward-receipt-upload`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  ChannelFinanceUserStateWiseData = async (formdata) => {
    return axios({
      method: "get",
      url: `/users/channel-financed-user-state-wise-data`,
      data: formdata,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  CreateUser = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/create-channel-finance-case`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  UpdateUser = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/update-channel-finance-case`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetUserExists = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-user-data-by-phone`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetListAppliedForChannelFinanceCase = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-list-of-applied-for-channel-finance-cases`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetChannelFinanceApprovalList = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-docs-verification-pending-completed-by-accounts-team`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  DocsApprovalAndReject = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/verification-or-updation-of-channel-finance-case`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAllDocsVerificationPendingCompletedByBusinessAnalyst = async (
    formdata
  ) => {
    return axios({
      method: "post",
      url: `/users/get-all-docs-verification-pending-completed-by-business-analyst-team`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAllApprovalPendingCompletedbyAdminAPI = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-docs-approval-pending-completed-by-admin`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAllApprovalPendingCompletedbyFinancier = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-docs-approval-pending-completed-by-financier`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAllPhysicalDocumentsPendingCollected = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-physical-documents-pending-collected`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAllPhysicalDocumentsPendingCollectedByAR = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-physical-documents-pending-collected-by-AR`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GetAllAgreementSignedPendingCompleted = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/get-all-agreement-signed-pending-completed`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetSendCreditLimitAndSanctionLatter = async (formdata) => {
    return axios({
      method: "post",
      url: `/users/credit-limit-and-sanction-letter`,
      data: formdata,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  GetBidderChannelFinanceCaseList = async (formData, source = {}) => {
    return axios({
      method: "post",
      url: `/users/get-bidder-channel-finance-case-list`,
      data: formData,
      cancelToken: source?.token,
    })
      .then((response) => {
        console.log({ response });
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  GlobalDataCountAPIChannelFinanceForAccount = async (formData) => {
    return axios({
      method: "get",
      url: `users/get-all-docs-verification-pending-completed-by-accounts-team-count`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GlobalDataCountAPIChannelFinanceForSuperAdmin = async (formData) => {
    return axios({
      method: "get",
      url: `users/get-all-docs-approval-pending-completed-by-admin-count`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GlobalDataCountAPIChannelFinanceForBA = async (formData) => {
    return axios({
      method: "post",
      url: `users/get-all-docs-verification-pending-completed-by-business-analyst-team`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
  GlobalDataCountAPIChannelFinanceForRisk = async (formData) => {
    return axios({
      method: "post",
      url: `users/get-all-agreement-signed-pending-completed`,
      data: formData,
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };

  ChannelFinanceRejectCase = async (formData) => {
    return axios({
      method: "post",
      url: `users/risk-channel-finance-reject-case`,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        errorToast(error?.message);
      });
  };
}

export default new ChannelFinanceService();
