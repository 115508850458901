import React from "react";
import { Modal } from "react-bootstrap";
import ErrorHandler from "../../common/ErrorHandler";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";

function ChannelFinanceCaseDetailsModal(props) {
  const { data } = props;

  try {
    return (
      <>
        <Modal
          {...props}
          size="lg"
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <span className="fs-5 fw-semibold">
              {data?.make_model} ({data?.reg_no})
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className=" mb-2">
              <div className=" mb-0 fw-semibold">
                Loan Status :
                <span className=" fw-bold text-uppercase">
                  {" "}
                  {data?.loan_status}
                </span>
              </div>
              <div className=" mb-0 fw-semibold">
                Asset Value :
                <span className=" fw-bold text-uppercase">
                  {" "}
                  {indianCurrencyFormater(data?.bid, 0)}
                </span>
              </div>
              <div className=" mb-0 fw-semibold">
                Loan Amount :
                <span className=" fw-bold text-uppercase">
                  {" "}
                  {indianCurrencyFormater(data?.loan_amount, 0)}
                </span>
              </div>
              <div className=" mb-0 fw-semibold">
                Down Payment :
                <span className=" fw-bold text-uppercase">
                  {" "}
                  {indianCurrencyFormater(data?.margin_amount, 0)}
                </span>
              </div>
            </div>

            <div className="row row-cols-1 row-cols-md-2 g-2">
              {data?.is_credit_request_letter_uploaded ? (
                <>
                  <div className=" px-2">
                    <div className=" mb-0 fw-semibold">
                      Credit Request Letter and Name Change Authorization Letter
                    </div>

                    {data?.is_credit_request_letter_uploaded ? (
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        href={data?.credit_request_letter[0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-cloud-arrow-down-fill"></i> Download
                      </a>
                    ) : (
                      <i className=" bi bi-circle-x-fill"></i>
                    )}
                  </div>
                </>
              ) : null}

              <div className=" col-12 mb-2">
                <label
                  htmlFor="broker_yard_parking_charges"
                  className="from-label mb-0"
                >
                  Yard Parking Charges
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={
                    !data?.is_broker_yard_parking_charges_available
                      ? "Not Available"
                      : indianCurrencyFormater(
                          data?.broker_yard_parking_charges,
                          0
                        )
                  }
                  disabled
                />
              </div>

              {data?.is_name_change_confirmation_uploaded ? (
                <>
                  <div className=" px-2">
                    <div className=" mb-0 fw-semibold">Yard Name</div>
                    <input
                      type="text"
                      value={data?.yard_details?.name}
                      disabled
                      className=" form-control"
                    />
                  </div>
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">
                      Bidder Advance Money UTR
                    </div>
                    <input
                      type="text"
                      value={data?.bidder_advanced_money_UTR}
                      disabled
                      className=" form-control"
                    />
                  </div>
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">
                      Yard Parking Charges UTR (Bidder)
                    </div>
                    <input
                      type="text"
                      value={
                        !data?.is_broker_parking_charges_UTR_available
                          ? "Not Available"
                          : data?.broker_parking_charges_UTR
                      }
                      disabled
                      className=" form-control"
                    />
                  </div>
                  <div className=" px-2">
                    <div className=" mb-0 fw-semibold">
                      Name Change Confirmation
                    </div>

                    {data?.is_name_change_confirmation_uploaded ? (
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        href={data?.name_change_confirmation[0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-cloud-arrow-down-fill"></i> Download
                      </a>
                    ) : (
                      <i className=" bi bi-circle-x-fill"></i>
                    )}
                  </div>
                  {/* <div className=" px-2">
                    <div className=" mb-0 fw-semibold">
                      Stock Inward Receipt
                    </div>

                    {data?.is_stock_inward_receipt_uploaded ? (
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        href={data?.stock_inward_receipt[0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-cloud-arrow-down-fill"></i> Download
                      </a>
                    ) : (
                      <i className=" bi bi-circle-x-fill"></i>
                    )}
                  </div> */}
                </>
              ) : null}

              {data?.is_seller_UTR_uploaded ? (
                <>
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">
                      {" "}
                      Seller Loan Disbursement UTR
                    </div>
                    <input
                      type="text"
                      value={data?.seller_UTR}
                      disabled
                      className=" form-control"
                    />
                  </div>
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">
                      {" "}
                      Loan Disbursed Date
                    </div>
                    <input
                      type="date"
                      value={data?.loan_disbursed_date}
                      disabled
                      className=" form-control"
                    />
                  </div>
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">
                      {" "}
                      Seller Payment Date
                    </div>
                    <input
                      type="date"
                      value={data?.seller_payment_date}
                      disabled
                      className=" form-control"
                    />
                  </div>
                </>
              ) : null}
              {data?.is_release_order_uploaded ? (
                <>
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">
                      {" "}
                      Yard Parking Charges UTR (Yard Owner)
                    </div>
                    <input
                      type="text"
                      value={
                        !data?.is_yard_parking_charges_UTR_available
                          ? "Not Available"
                          : data?.yard_parking_charges_UTR
                      }
                      disabled
                      className=" form-control"
                    />
                  </div>
                  <div className=" px-2">
                    <div className=" mb-0 fw-semibold">
                      Financier Release Order
                    </div>

                    {data?.financier_release_order?.length > 0 ? (
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        href={data?.financier_release_order[0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-cloud-arrow-down-fill"></i> Download
                      </a>
                    ) : (
                      <i className=" bi bi-circle-x-fill"></i>
                    )}
                  </div>
                  {/* <div className=" px-2">
                    <div className=" mb-0 fw-semibold">MT Release Order</div>

                    {data?.mt_release_order?.length > 0 ? (
                      <a
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                        href={data?.mt_release_order[0]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="bi bi-cloud-arrow-down-fill"></i> Download
                      </a>
                    ) : (
                      <i className=" bi bi-circle-x-fill"></i>
                    )}
                  </div> */}
                </>
              ) : null}

              {data?.is_yard_owner_uploaded_data_sent_for_verification && (
                <div className=" px-2">
                  <div className=" mb-0 fw-semibold">Stock Inward Receipt</div>

                  {data?.stock_inward_receipt?.length > 0 ? (
                    <a
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      href={data?.stock_inward_receipt[0]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    <i className=" bi bi-circle-x-fill"></i>
                  )}
                </div>
              )}

              {data?.is_rejected ? (
                <>
                  {data.is_refunded ? (
                    <div className="px-2">
                      <div className=" mb-0 fw-semibold">Broker Refund UTR</div>
                      <input
                        type="text"
                        value={data?.broker_refund_UTR}
                        disabled
                        className=" form-control"
                      />
                    </div>
                  ) : null}
                  <div className="px-2">
                    <div className=" mb-0 fw-semibold">Rejection Reason</div>
                    <span>
                      {data?.rejection_reason}{" "}
                      {data?.other_rejection_reason
                        ? `(${data?.other_rejection_reason})`
                        : ""}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
}

export default ChannelFinanceCaseDetailsModal;
