import { Modal } from "react-bootstrap";

function ChannelFinanceTrackLoanStatus(props) {
  const { asset, show, onHide } = props;
  const LoanTrackingMilestones = [
    { name: "Loan Applied", status: true },
    {
      name: "Cleared by MT Risk Team",
      status: asset?.is_credit_request_letter_uploaded,
    },
    {
      name: "Cleared by MT Operations Team",
      status: asset?.is_name_change_confirmation_uploaded,
    },
    {
      name:
        asset?.is_rejected && asset?.is_case_closed
          ? "Loan Rejected"
          : "Loan Disbursed",
      status:
        asset?.is_rejected && asset?.is_case_closed
          ? true
          : asset?.is_seller_UTR_uploaded,
    },
  ];

  const LoanTrackingMilestonesForRejectedCase = [
    { name: "Loan Applied", status: true },
    {
      name: asset?.is_rejected && asset?.is_case_closed ? "Loan Rejected" : "",
      status: asset?.is_rejected && asset?.is_case_closed,
    },
  ];

  const trackingArray =
    asset?.is_rejected && asset?.is_case_closed
      ? LoanTrackingMilestonesForRejectedCase
      : LoanTrackingMilestones;

  return (
    <>
      <Modal show={show} centered onHide={onHide} backdrop="static">
        <Modal.Header closeButton>
          <h6 className=" h6">
            Loan Status : {asset?.make_model} - {asset?.reg_no}
          </h6>
        </Modal.Header>
        <Modal.Body>
          <div>
            {trackingArray?.map((val, i) => {
              return (
                <div key={val} className="d-flex flex-column">
                  <div className=" d-flex justify-content-start">
                    <span className=" d-flex justify-content-start align-items-center flex-column">
                      {val?.status ? (
                        asset?.is_rejected &&
                        asset?.is_case_closed &&
                        i === trackingArray.length - 1 ? (
                          <i className="bi bi-x-circle-fill text-danger fs-6"></i>
                        ) : (
                          <i className="bi bi-check-circle-fill text-success fs-6"></i>
                        )
                      ) : (
                        <span className=" p-2 rounded-circle my-1 bg-secondary"></span>
                      )}{" "}
                    </span>
                    <span
                      className={`ms-2 fw-semibold ${
                        asset?.is_rejected &&
                        asset?.is_case_closed &&
                        i === trackingArray.length - 1 &&
                        " text-danger"
                      }`}
                    >
                      {val?.name}
                      {asset?.is_rejected &&
                        asset?.is_case_closed &&
                        i === trackingArray.length - 1 &&
                        ` (Reason : ${asset?.rejection_reason} ${
                          asset?.other_rejection_reason
                            ? " - " + asset?.other_rejection_reason
                            : ""
                        } )`}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChannelFinanceTrackLoanStatus;
