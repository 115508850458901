import React, { useEffect, useState } from "react";
import ErrorHandler from "../../common/ErrorHandler";
import UserNameAndDealershipDetails from "../../common/UserNameAndDealershipDetails";
import { errorToast } from "../../react-toastfiy/toast";
import ChannelFinanceDocumentsVerificationForBusinessAnalyst from "./ChannelFinanceDocumentsVerificationForBusinessAnalyst";
import { useHistory } from "react-router-dom";
import channelFinanceService from "../../services/channelFinance/channelFinance.service";

const BusinessAnalystDashboard = () => {
  const history = useHistory();

  const [channelFinancePendingCount, setChannelFinancePendingCount] =
    useState("0");

  const [active, setactive] = useState("task_board");

  useEffect(() => {

    const form_data = new FormData();
    form_data.append("count", true);
    channelFinanceService
      .GlobalDataCountAPIChannelFinanceForBA(form_data)
      .then((res) => {
        if (res.status === 200) {
          setChannelFinancePendingCount(res?.count ?? 0);
        } else {
          errorToast(res?.msg);
        }
      });
  }, [active]);

  useEffect(() => {
    document.title = "Dashboard";
    let active_tab_acc = localStorage.getItem("active_tab_acc");
    console.log({ active_tab_acc });
    if (active_tab_acc) {
      !!active_tab_acc && setactive(active_tab_acc);
    } else {
      localStorage.setItem("active_tab_acc", "task_board");
    }
  }, []);
  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");
    setactive(id);
    localStorage.setItem("active_tab_acc", id);
  };

  try {
    return (
      <>
        <UserNameAndDealershipDetails />

        <div className="container responsive mt-2 ">
          <div className="d-flex justify-content-between">
            <i
              className="bi bi-arrow-left-circle"
              onClick={() => history.goBack()}
              style={{
                fontSize: "xx-large",
                color: "black",
                backgroundColor: "white",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            ></i>
          </div>
        </div>

        <div className="container mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`${
                  active === "task_board"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                } position-relative `}
                id="task_board"
                onClick={handlechangetab}
              >
                Taskboard
                {channelFinancePendingCount !== 0 && (
                  <span
                    id="channel_finance"
                    className="position-absolute top-0 ms-3 start- translate-middle badge rounded-pill bg-danger"
                  >
                    {channelFinancePendingCount}
                  </span>
                )}
              </button>
            </li>
          </ul>
        </div>
        {active === "task_board" && (
          <>
            <ChannelFinanceDocumentsVerificationForBusinessAnalyst />
          </>
        )}
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
  // }
};

export default BusinessAnalystDashboard;
