import React from "react";
import { Modal } from "react-bootstrap";
import { viewURL } from "../../helpers/functions/stringFormatter";
import { indianCurrencyFormater } from "../../helpers/functions/currencyFormater";
import { formatDateDDMMYYYY } from "../../helpers/functions/dateFormatter";

const ChannelFinanceViewDocumentsModal = (props) => {
  const { show, data, onHide } = props;
  console.log({ data, show, onHide, viewURL });
  const rowClass =
    "d-flex justify-content-start flex-column col-md-6 col-12 p-2 col-xl-4";
  try {
    return (
      <>
        <Modal
          size="lg"
          backdrop="static"
          aaria-labelledby="contained-modal-title-vcenter"
          centered
          show={props.show}
          onHide={props.onHide}
        >
          <Modal.Header closeButton>
            {`${data?.first_name} ${data?.middle_name} ${data?.last_name}-${data?.phone}`}
          </Modal.Header>
          <Modal.Body>
            <div className=" row">
              <div className={rowClass}>
                <span className=" fw-semibold">Name</span>
                <span>{`${data?.first_name} ${data?.middle_name} ${data?.last_name}`}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Phone number</span>
                <span>{data?.phone}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Address</span>
                <span>{data?.address ? data?.address : "-"}</span>
              </div>
            </div>
            <div className="row">
              <div className={rowClass}>
                <span className=" fw-semibold">AADHAAR</span>
                <span>{data?.aadhar_no}</span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">AADHAAR front</span>
                <span>
                  {data?.aadhar_front_url && (
                    <a
                      href={viewURL(data?.aadhar_front_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className="bi bi-eye-fill"></i> View
                    </a>
                  )}{" "}
                  |
                  {data?.aadhar_front_url ? (
                    <a
                      href={data?.aadhar_front_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    "No document"
                  )}
                </span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">AADHAAR back</span>
                <span>
                  {data?.aadhar_back_url && (
                    <a
                      href={viewURL(data?.aadhar_back_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className="bi bi-eye-fill"></i> View
                    </a>
                  )}{" "}
                  |
                  {data?.aadhar_back_url ? (
                    <a
                      href={data?.aadhar_back_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    " No document"
                  )}
                </span>
              </div>
            </div>
            <div className=" row">
              <div className={rowClass}>
                <span className=" fw-semibold">PAN</span>
                <span>{data?.pan_no}</span>
              </div>
              <div className={`${rowClass}`}>
                <span className=" fw-semibold">PAN file</span>
                <span>
                  {data?.pan_url && (
                    <a
                      href={viewURL(data?.pan_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className="bi bi-eye-fill"></i> View
                    </a>
                  )}{" "}
                  |{" "}
                  {data?.pan_url ? (
                    <a
                      href={data?.pan_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    " No document"
                  )}
                </span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Bank Statement</span>
                <span>
                  {data?.bank_statement_url && (
                    <a
                      href={viewURL(data?.bank_statement_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className="bi bi-eye-fill"></i> View
                    </a>
                  )}{" "}
                  |{" "}
                  {data?.bank_statement_url ? (
                    <a
                      href={data?.bank_statement_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    " No document"
                  )}
                </span>
              </div>
            </div>

            <div className=" row">
              <div className={`${rowClass}`}>
                <span className=" fw-semibold">GST Certificate</span>
                <span>
                  {data?.gst_reg_certificate_url && (
                    <a
                      href={viewURL(data?.gst_reg_certificate_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className="bi bi-eye-fill"></i> View
                    </a>
                  )}{" "}
                  |{" "}
                  {data?.gst_reg_certificate_url ? (
                    <a
                      href={data?.gst_reg_certificate_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    " No document"
                  )}
                </span>
              </div>
              <div className={rowClass}>
                <span className=" fw-semibold">Constitutional Documents</span>
                <span>
                  {data?.constitutional_documents_url && (
                    <a
                      href={viewURL(data?.constitutional_documents_url)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      {" "}
                      <i className="bi bi-eye-fill"></i> View
                    </a>
                  )}{" "}
                  |{" "}
                  {data?.constitutional_documents_url ? (
                    <a
                      href={data?.constitutional_documents_url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <i className=" bi bi-cloud-arrow-down-fill"></i> Download
                    </a>
                  ) : (
                    " No document"
                  )}
                </span>
              </div>
              {data?.tc_credit_limit ? (
                <div className={rowClass}>
                  <span className=" fw-semibold"> Credit Limit</span>
                  <span>
                    <span>{indianCurrencyFormater(data?.tc_credit_limit)}</span>
                  </span>
                </div>
              ):""}
            </div>

            <div className=" row">
              {data?.tc_upfront_margin ? (
                <>
                  <div className={rowClass}>
                    <span className=" fw-semibold">Upfront Margin</span>
                    <span>
                      <span>{data?.tc_upfront_margin}%</span>
                    </span>
                  </div>
                </>
              ):""}

              <div className={rowClass}>
                {data?.agreement_url && (
                  <>
                    <span className=" fw-semibold">Sanction letter</span>
                    <span>
                      {data?.sanction_letter_url && (
                        <a
                          href={viewURL(data?.sanction_letter_url)}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          {" "}
                          <i className="bi bi-eye-fill"></i> View
                        </a>
                      )}{" "}
                      |{" "}
                      {data?.sanction_letter_url ? (
                        <a
                          href={data?.sanction_letter_url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <i className=" bi bi-cloud-arrow-down-fill"></i>{" "}
                          Download
                        </a>
                      ) : (
                        " No document"
                      )}
                    </span>
                  </>
                )}
              </div>
              <div className={rowClass}>
                {data?.agreement_url && (
                  <>
                    <span className=" fw-semibold">Agreement</span>
                    <span>
                      {data?.agreement_url && (
                        <a
                          href={viewURL(data?.agreement_url)}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          {" "}
                          <i className="bi bi-eye-fill"></i> View
                        </a>
                      )}{" "}
                      |{" "}
                      {data?.agreement_url ? (
                        <a
                          href={data?.agreement_url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <i className=" bi bi-cloud-arrow-down-fill"></i>{" "}
                          Download
                        </a>
                      ) : (
                        " No document"
                      )}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className=" row">
              <div className={rowClass}>
                {data?.courier_no && (
                  <>
                    <span className=" fw-semibold">Courier No</span>
                    <span>{data?.courier_no ? data?.courier_no : ""}</span>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer> </Modal.Footer>
        </Modal>
      </>
    );
  } catch (error) {
    console.log(error);
  }
};

export default ChannelFinanceViewDocumentsModal;
