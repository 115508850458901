import React, { useEffect, useState } from "react";
import ErrorHandler from "../../common/ErrorHandler";
import { errorToast } from "../../react-toastfiy/toast";
import ChannelFinanceAddEditViewUser from "../channelFinance/ChannelFinanceAddEditViewUser";
import UnifiedUsersList from "./UnifiedUsersList";

const AddEditOrViewUserByAuctionRepresentative = () => {
  const currentUser = localStorage.getItem("refreshToken");

  const [active, setactive] = useState("");

  useEffect(() => {
    try {
      document.title = "Dashboard";
  
    } catch (error) {
      console.error(error);
      errorToast(error?.message);
    }
  }, []);

  const handlechangetab = (e) => {
    const id = e.target.getAttribute("id");

    setactive(id);
    localStorage.setItem("active_sub_tab", id);
  };

  useEffect(() => {
    const lastActiveTab = localStorage.getItem("active_sub_tab");
    if (lastActiveTab) {
      setactive(lastActiveTab);
    }
  }, []);

  try {
    return (
      <>
        <div className="container mt-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={
                  active === "add_auction_user"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="add_auction_user"
                onClick={handlechangetab}
              >
                Add Auction User
              </button>
            </li>
            <li className="nav-item">
              <button
                className={
                  active === "channel_finance"
                    ? `nav-link active btn btn-link`
                    : `nav-link btn btn-link`
                }
                id="channel_finance"
                onClick={handlechangetab}
              >
                Channel Finance
              </button>
            </li>
          </ul>
        </div>

        {active === "add_auction_user" && (
          <>
            <UnifiedUsersList />
          </>
        )}
        {active === "channel_finance" && (
          <>{<ChannelFinanceAddEditViewUser /> }</>
        )}
      </>
    );
  } catch (error) {
    return <ErrorHandler error={error} />;
  }
};

export default AddEditOrViewUserByAuctionRepresentative;
