import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";
import SearchBox from "../../common/SearchBox";
import TableLoading from "../../common/TableLoading";
import { errorToast, successToast } from "../../react-toastfiy/toast";
import channelFinanceService from "../../services/channelFinance/channelFinance.service";
import stateService from "../../services/state.service";
import ChannelFinanceForm from "../user/ChannelFinanceForm";
import ChannelFinanceViewDocumentsModal from "./ChannelFinanceViewDocumentsModal";

const ChannelFinanceAddEditViewUser = () => {
  const token = localStorage.getItem("refreshToken");
  const user = jwtDecode(token);

    useEffect(() => {
      try {
        document.title = "Channel Finance";
      } catch (error) {
        console.error(error);
        errorToast(error?.message);
      }
    }, []);

  const financierOptionSelect = [
    {
      value: "pending",
      label: "Pending",
    },
    {
      value: "approved",
      label: "Sent",
    },
  ];

  const [viewDocModal, setViewDocModal] = useState(false);
  const [viewDocModalData, setViewDocModalData] = useState();
  const [loading, setLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [approvalDataList, setApprovalDataList] = useState([]);
  const [filterApprovalDataList, setFilterApprovalDataList] = useState([]);
  const [channelFinance, setChannelFinance] = useState(false);
  const [channelFinanceModalData, setChannelFinanceModalData] = useState({});
  const [editForm, setEditForm] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState("Add User Type");
  const [selectedValue, setSelectedValue] = useState("all");
  const [financierFilterApprovalDataList, setFinancierFilterApprovalDataList] =
    useState([]);
    
  const [financierSelectStatus, setFinancierSelectStatus] = useState({
    value: "pending",
    label: "Pending",
  });
  const [financierApprovalDataList, setFinancierApprovalDataList] = useState(
    []
  );
  const [loadingCollectionPending, setLoadingCollectionPending] =
    useState(false);

  const [selectedValueCollectionPending, setSelectedValueCollectionPending] =
    useState("all");

  useEffect(() => {
    try {
      setLoadingCollectionPending(true);
      const form_financier_data = new FormData();
      form_financier_data.append("status", "pending");
      channelFinanceService
        .GetAllPhysicalDocumentsPendingCollectedByAR(form_financier_data)
        .then((res) => {
          setLoadingCollectionPending(false);
          if (res?.status === 200) {
            setFinancierApprovalDataList(res?.data);
            setFinancierFilterApprovalDataList(res?.data);
          } else {
            errorToast(res?.msg);
          }
        });
    } catch (error) {
      errorToast(error?.msg);
    }
  }, []);

  useEffect(() => {
    try {
      if (channelFinance === false) {
        setLoading(true);
        const form_data = new FormData();
        form_data.append("status", user?.phone);
        channelFinanceService
          .GetListAppliedForChannelFinanceCase()
          .then((res) => {
            setLoading(false);
            if (res?.status === 200) {
              setApprovalDataList(res?.data);
              setFilterApprovalDataList(res?.data);
            } else {
              errorToast(res?.msg);
            }
          });
      }
    } catch (error) {}
  }, []);

  useEffect(() => {
    try {
      setStateLoading(true);
      stateService.getAllState().then((res) => {
        setStateLoading(false);
        if (res?.length > 0) {
          const state_list = res.map((val) => ({
            label: val?.state_name,
            value: val,
          }));
          const new_state_list = [
            { label: "All", value: "all" },
            ...state_list,
          ];
          setStateList(new_state_list);
        }
      });
    } catch (error) {}
  }, []);

  const handleSelect = (e) => {
    console.log(e?.label);
    if (e?.label !== "All") {
      const new_Data = approvalDataList.filter(
        (val, key) => val?.state === e?.label
      );
      setFilterApprovalDataList(new_Data);
      setSelectedValue(e?.label);
    } else {
      console.log({ approvalDataList });
      setSelectedValue(e?.label);
      setFilterApprovalDataList(approvalDataList);
    }
  };

  const handleSelectStateCollectionPending = (e) => {
    if (e?.label !== "All") {
      console.log(e?.label);
      const new_Data = financierApprovalDataList.filter(
        (val, key) => val?.state === e?.label
      );
      setFinancierFilterApprovalDataList(new_Data);
      setSelectedValueCollectionPending(e?.label);
    } else {
      setFinancierFilterApprovalDataList(financierApprovalDataList);
      setSelectedValueCollectionPending(e?.label);
    }
  };

  const handleDocumentsCollection = (e, case_id) => {
    try {
      e.preventDefault();
      Swal.fire({
        title: "Courier no",
        showCancelButton: true,
        confirmButtonText: `Submit`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        reverseButtons: true,
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
          placeholder: "Enter Courier no",
        },
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: async (value) => {
          if (!value || value.trim() === "") {
            Swal.showValidationMessage("Please Enter Courier no ");
            return;
          }
          try {
            const courier_no = value;
            let close_request_data = new FormData();
            close_request_data.append("status", "True");
            close_request_data.append("courier_no", courier_no);
            close_request_data.append(
              "request_type",
              "physical_documents_sent"
            );
            close_request_data.append("case_id", case_id);
            const response = await channelFinanceService.DocsApprovalAndReject(
              close_request_data
            );
            if (response.status === 200) {
              setFinancierSelectStatus({
                value: "pending",
                label: "Pending",
              });
              successToast(response.msg);
              setLoadingCollectionPending(true);
              const form_financier_data = new FormData();
              form_financier_data.append("status", "pending");
              channelFinanceService
                .GetAllPhysicalDocumentsPendingCollectedByAR(
                  form_financier_data
                )
                .then((res) => {
                  setLoadingCollectionPending(false);
                  if (res?.status === 200) {
                    setFinancierApprovalDataList(res?.data);
                    setFinancierFilterApprovalDataList(res?.data);
                  } else {
                    errorToast(res?.msg);
                  }
                });
            }
            response.status !== 200 && errorToast(response.msg);
          } catch (error) {
            console.error(error);
            errorToast(error?.message);
          }
        },
      });
    } catch (error) {}
  };

  const handleFinancierSelect = (e) => {
    try {
      setLoadingCollectionPending(true);
      setFinancierSelectStatus(e);
      const form_financier_data = new FormData();
      form_financier_data.append("status", e.value);
      channelFinanceService
        .GetAllPhysicalDocumentsPendingCollectedByAR(form_financier_data)
        .then((res) => {
          setLoadingCollectionPending(false);
          if (res?.status === 200) {
            setFinancierApprovalDataList(res?.data);
            setFinancierFilterApprovalDataList(res?.data);
          } else {
            errorToast(res?.msg);
          }
        });
    } catch (error) {
      errorToast(error?.msg);
    }
  };

  return (
    <>
      {channelFinance && (
        <ChannelFinanceForm
          show={channelFinance}
          edit_form={editForm}
          setFilterApprovalDataList={setFilterApprovalDataList}
          setApprovalDataList={setApprovalDataList}
          setChannelFinance={setChannelFinance}
          data={channelFinanceModalData}
          onHide={() => {
            setEditForm(false);
            setChannelFinance(false);
            setDropdownLabel("Add User Type");
          }}
        />
      )}

      {viewDocModal && (
        <ChannelFinanceViewDocumentsModal
          show={viewDocModal}
          data={viewDocModalData}
          onHide={() => {
            setViewDocModal(false);
          }}
        />
      )}

      <div className="container responsive mt-2 ms-auto ">
        <div
          className="row mt-2"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div className="col-10 col-sm-12 col-md-8 col-lg-8 d-flex d-flex-wrap justify-content-between mt-2">
            <h4 className=""> Channel Finance Onboarding</h4>
          </div>

          <div
            className="col-12 col-sm-12 col-md-4 col-lg-4  mt-2 d-flex d-flex-wrap justify-content-end"
            style={{ marginBottom: "4px" }}
          >
            <button
              className="btn btn-primary"
              href="#"
              onClick={() => {
                setDropdownLabel("Add User Type");
                setChannelFinance(true);
              }}
            >
              <i className="bi bi-plus" style={{ cursor: "pointer" }}></i>
              Add Channel Finance User
            </button>
          </div>
        </div>

        <div
          className="row mt-2"
          style={{ marginLeft: "0px", marginRight: "0px" }}
        >
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-3 mt-2"
            style={{
              marginBottom: "4px",
              fontSize: "small",
              position: "sticky",
              top: 0,
              zIndex: 6,
            }}
          >
            <Select
              options={stateList}
              onChange={handleSelect}
              value={stateList.find((option) => option.value === selectedValue)}
              placeholder="Select State"
            />
          </div>
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-3  mt-2 d-flex d-flex-wrap ms-auto"
            style={{ marginBottom: "4px" }}
          >
            <SearchBox
              placeholder="Search by First Name, Phone,State"
              allData={approvalDataList}
              setFilteredData={setFilterApprovalDataList}
            />
          </div>
        </div>
      </div>

      <div className="container mt-2">
        <div className="d-flex d-flex-wrap justify-content-between">
          <div
            className="table-responsive mt-3 container"
            style={{ minHeight: "5rem", maxHeight: "60vh" }}
          >
            <table className="table table-bordered table-hover">
              <thead className="thead-dark">
                <tr
                  className="text-center"
                  style={{
                    fontSize: "small",
                    position: "sticky",
                    top: -9,
                    zIndex: 2,
                  }}
                >
                  <th>No.</th>
                  <th>Phone</th>
                  <th>Name</th>
                  <th>Created By</th>
                  <th>State</th>
                  <th>View Details</th>
                  <th>Current Status</th>
                  <th>Rejection Reason</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <TableLoading rows={5} cols={9} />
                ) : (
                  Object.entries(filterApprovalDataList).map(
                    ([key, value], index) => (
                      <tr className="text-center align-middle" key={index}>
                        <td>{index + 1}</td>
                        <td>{value?.phone}</td>
                        <td>
                          {`${value?.first_name} ${value?.middle_name || ""} ${
                            value?.last_name
                          }`.trim()}
                        </td>
                        <td>{value?.created_by_name}</td>
                        <td>{value?.state}</td>

                        <td className="text-nowrap">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setViewDocModal(true);
                              setViewDocModalData(value);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="bi bi-eye-fill text-white"></i> View
                          </button>
                        </td>
                        <td>
                          <b>{value?.current_status}</b>
                        </td>
                        {value?.rejection_reason === "" ? (
                          <td>-</td>
                        ) : (
                          <td className="text-danger">
                            <b>{value?.rejection_reason}</b>
                          </td>
                        )}
                        <td>
                          <i
                            className="bi bi-pencil-square mx-3"
                            id={value}
                            onClick={(e) => {
                              if (value?.is_rejected !== false) {
                                setChannelFinanceModalData(value);
                                setChannelFinance(true);
                                setDropdownLabel("Edit User Type");
                                setEditForm(true);
                              }
                            }}
                            style={{
                              cursor:
                                value?.is_rejected === false
                                  ? "not-allowed"
                                  : "pointer",
                              color:
                                value?.is_rejected === false
                                  ? "gray"
                                  : "inherit",
                            }}
                            data-tooltip-id="edit-tooltip"
                            data-bs-placement="top"
                            data-tooltip-content={
                              value?.is_rejected === false
                                ? "Edit disabled"
                                : "Edit"
                            }
                          ></i>
                          {/* {value?.is_rejected !== false && ( */}
                          <Tooltip id="edit-tooltip" style={{ zIndex: 2 }} />
                          {/* )} */}
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Object.entries(filterApprovalDataList)?.length === 0 && !loading && (
        <p className=" fw-semibold text-danger text-center">No Data</p>
      )}

      <div className="container responsive mt-2 ms-auto ">
        <div className="row mt-2 ms-1">
          <div className="col-10 col-sm-12 col-md-6 col-lg-6 d-flex d-flex-wrap justify-content-between mt-2">
            <h4 style={{ marginLeft: "-6px" }} className="">
              Channel Finance Documents Sent
            </h4>
          </div>

          <div className="d-flex flex-wrap justify-content-between ">
            <div
              className="col-12 col-sm-12 col-md-4 col-lg-3 mt-2"
              style={{
                marginBottom: "4px",
                fontSize: "small",
                position: "sticky",
                top: 0,
                zIndex: 5,
                marginLeft: "-5px",
              }}
            >
              <Select
                options={stateList}
                onChange={handleSelectStateCollectionPending}
                value={stateList.find(
                  (option) => option.value === selectedValueCollectionPending
                )}
                placeholder="Select State"
              />
            </div>

            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3 mt-2"
              style={{
                marginBottom: "4px",
                fontSize: "small",
                position: "sticky",
                top: 0,
                zIndex: 4,
                marginLeft: "-5px",
              }}
            >
              <Select
                options={financierOptionSelect}
                onChange={handleFinancierSelect}
                value={financierSelectStatus}
                placeholder="Select Documents Status"
              />
            </div>
            <div
              className="col-12 col-sm-12 col-md-3 col-lg-3  mt-2 me-2"
              style={{ marginBottom: "4px", marginLeft: "-7px" }}
            >
              <SearchBox
                placeholder="Search by First Name, Phone,State"
                allData={financierApprovalDataList}
                setFilteredData={setFinancierFilterApprovalDataList}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="d-flex d-flex-wrap justify-content-between">
          <div
            className="table-responsive mt-3 container"
            style={{ minHeight: "5rem", maxHeight: "60vh" }}
          >
            <table className="table table-bordered table-hover">
              <thead className="thead-dark">
                <tr
                  className="text-center"
                  style={{
                    fontSize: "small",
                    position: "sticky",
                    top: -9,
                    zIndex: 2,
                  }}
                >
                  <th>No</th>
                  <th>Phone</th>
                  <th>Name</th>
                  <th>Created By</th>
                  <th>State</th>
                  <th>View Details</th>
                  <th>Current Status</th>
                  {financierSelectStatus?.value !== "pending" && (
                    <th>Courier No</th>
                  )}

                  {financierSelectStatus?.value === "pending" && (
                    <th
                      style={{
                        minWidth: "6rem",
                        position: "sticky",
                        top: 0,
                        right: "-15px",
                        zIndex: 3,
                      }}
                    >
                      Action
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {loadingCollectionPending ? (
                  <TableLoading rows={5} cols={8} />
                ) : (
                  Object.entries(financierFilterApprovalDataList).map(
                    ([key, value], index) => (
                      <tr className="text-center" key={index}>
                        <td>{index + 1}</td>
                        <td>{value?.phone}</td>
                        <td>
                          {`${value?.first_name} ${value?.middle_name || ""} ${
                            value?.last_name
                          }`.trim()}
                        </td>
                        <td>{value?.created_by_name}</td>
                        <td>{value?.state}</td>
                        <td className="text-nowrap">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setViewDocModal(true);
                              setViewDocModalData(value);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="bi bi-eye-fill text-white"></i> View
                          </button>
                        </td>
                        <td>
                          <b>{value?.current_status}</b>
                        </td>
                        {financierSelectStatus?.value !== "pending" && (
                          <td>
                            <b>{value?.courier_no}</b>
                          </td>
                        )}

                        {financierSelectStatus?.value === "pending" && (
                          <td
                            style={{
                              minWidth: "6rem",
                              position: "sticky",
                              top: 0,
                              right: "-15px",
                              zIndex: 3,
                            }}
                          >
                            <div className="d-flex d-flex-wrap justify-content-center">
                              <button
                                className="btn btn-success btn-sm"
                                onClick={(e) => {
                                  handleDocumentsCollection(e, value?.case_id);
                                }}
                                disabled={
                                  financierSelectStatus?.value === "send"
                                }
                              >
                                Mark as Sent
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {Object.entries(financierFilterApprovalDataList)?.length === 0 &&
        !loadingCollectionPending && (
          <p className=" fw-semibold text-danger text-center">No Data</p>
        )}
    </>
  );
};

export default ChannelFinanceAddEditViewUser;
